var render = function render(){var _vm=this,_c=_vm._self._c;return _c('header',{staticClass:"header nav-menu",attrs:{"noprint":""}},[_c('div',_vm._b({staticClass:"inner primary-color"},'div',_vm.getAllData,false),[(_vm.language)?_c('div',{staticClass:"modulos-muestra"},[_c('div',{staticClass:"inner-brand logo-container"},[_c('router-link',{attrs:{"to":{ path: '/', hash: '#home' }}},[_c('h1',{staticClass:"header__title"},[_vm._v(_vm._s(_vm.models))]),_c('h2',{staticClass:"third-font logo-container-title"},[_vm._v(_vm._s(_vm.title))]),(_vm.language)?_c('div',{staticClass:"logo-container-sub"},_vm._l((_vm.language),function(item,index){return _c('div',{key:index,staticClass:"logo-container-item"},[_c('p',{staticClass:"logo-container-subitem"},[_vm._v(_vm._s(item))])])}),0):_vm._e()])],1)]):_vm._e(),(_vm.headerData && _vm.isAllElements)?_c('ul',{staticClass:"inner-nav"},[_c('li',{staticClass:"inner-nav-menu"},[_c('ul',{staticClass:"display-flex",attrs:{"capitalize":""}},[_c('li',[_c('router-link',{staticClass:"button",attrs:{"to":{ path: '/', hash: '#home' }}},[_vm._v(" "+_vm._s(_vm.headerData.menu.home)+" ")])],1),_c('li',[_c('router-link',{staticClass:"button",attrs:{"to":{ path: '/', hash: '#services' }}},[_vm._v(" "+_vm._s(_vm.headerData.menu.services)+" ")])],1),_c('li',[_c('router-link',{staticClass:"button",class:{
                'router-link-exact-active': _vm.linkIsActive('/webcast'),
              },attrs:{"to":{ path: '/webcast', hash: '#Webcast' }}},[_vm._v(" "+_vm._s(_vm.headerData.menu.webcast)+" ")])],1),_c('li',{staticClass:"drop-down single hover ltr"},[_c('div',{staticClass:"button",class:{
                'router-link-exact-active': _vm.linkIsActive('/gallery') || _vm.linkIsActive('/help'),
              }},[_c('label',{staticClass:"drop-down-label display-flex"},[_c('span',[_vm._v(_vm._s(_vm.headerData.menu.gallery))]),_c('i',{staticClass:"fa fa-caret-down right"})]),_c('div',{staticClass:"drop-down-list list"},[_c('router-link',{class:{ 'router-link-exact-active': _vm.linkIsActive('/gallery') },attrs:{"to":{ path: '/gallery', hash: '#gallery' }}},[_vm._v(" "+_vm._s(_vm.headerData.menu.gallery)+" ")]),_c('div',{on:{"click":_vm.openMagazine}},[_vm._v(_vm._s(_vm.headerData.menu.magazine))]),_c('router-link',{attrs:{"to":{ path: '/', hash: '#galleryVideos' }}},[_vm._v(_vm._s(_vm.headerData.menu.shortfilms))]),_c('router-link',{attrs:{"to":{ path: '/', hash: '#films' }}},[_vm._v(_vm._s(_vm.headerData.menu.films))])],1)])]),_c('li',[_c('router-link',{staticClass:"button",attrs:{"to":{ path: '/', hash: '#testimonials' }}},[_vm._v(" "+_vm._s(_vm.headerData.menu.testimonials)+" ")])],1),_c('li',[_c('div',{staticClass:"button",on:{"click":() => _vm.goToSiblingApp('global')}},[_vm._v(" "+_vm._s(_vm.headerData.menu.global)+" ")])]),_c('li',[_c('div',{staticClass:"button",on:{"click":() => _vm.goToSiblingApp('concurso')}},[_vm._v(" "+_vm._s(_vm.headerData.menu.contest)+" ")])]),_c('li',[_c('router-link',{staticClass:"button",class:{ 'router-link-exact-active': _vm.linkIsActive('/blog') },attrs:{"to":{ path: '/blog', hash: '#blog' }}},[_vm._v(" "+_vm._s(_vm.headerData.menu.blog)+" ")])],1),_c('li',{staticClass:"drop-down single hover ltr"},[_c('div',{staticClass:"button",class:{
                'router-link-exact-active': _vm.linkIsActive('/contact') || _vm.linkIsActive('/help'),
              }},[_c('label',{staticClass:"drop-down-label display-flex"},[_c('span',[_vm._v(_vm._s(_vm.headerData.menu.contact))]),_c('i',{staticClass:"fa fa-caret-down right"})]),_c('div',{staticClass:"drop-down-list list"},[_c('router-link',{attrs:{"to":{ path: '/contact', hash: '#contact' }}},[_vm._v(" "+_vm._s(_vm.headerData.menu.contact)+" ")]),_c('router-link',{attrs:{"to":{ path: '/jobs', hash: '#jobs' }}},[_vm._v(_vm._s(_vm.headerData.menu.jobs))]),_c('router-link',{attrs:{"to":{ path: '/help', hash: '#help' }}},[_vm._v(_vm._s(_vm.headerData.menu.faq))])],1)])]),_c('li',[_c('signup-button',{staticClass:"btn",attrs:{"title":_vm.headerData.menu.signup,"mclass":"glowing"}})],1)])]),_c('li',{staticClass:"mbl-menu-btn"},[_c('div',{on:{"click":_vm.mobileMenu}},[_c('div',{staticClass:"line"}),_c('div',{staticClass:"line"}),_c('div',{staticClass:"line"})])]),_c('LangDropdown',{staticClass:"inner-nav__languageModal",attrs:{"iconSize":"35","currentLocale":_vm.getLocale,"optsLang":_vm.headerData.languages},on:{"localeChange":_vm.changeLang}})],1):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }